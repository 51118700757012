import React from 'react';

import DownloadQr from './components';

/**
 * Function to manage device qr  
 * @returns 
 */
function DeviceQrManagement() {
  return <DownloadQr />;
}

export default DeviceQrManagement;
