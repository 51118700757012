import React from 'react';

import { QrDetailsProperties } from '../../../../interface/qr-generator.interface';
import CustomFileInput from '../../../../shared-components/custom-file-input/CustomFileInput';
import CustomInput from '../../../../shared-components/custom-input/CustomInput';

interface QrCodeInputFieldProperties {
  qrDetails: QrDetailsProperties;
  handleChangeFunction: (name: string, value: string | number | File[]) => void;
  handleRemoveFile: (
    name: string,
    url: string,
    indexToRemove: number,
    type: 'newFile' | 'existingFile',
  ) => void;
}

/**
 * Function to handle Qr code user input
 * @param properties
 * @returns
 */
function QrCodeInputField(properties: QrCodeInputFieldProperties) {
  const { qrDetails, handleChangeFunction, handleRemoveFile } = properties;
  return (
    <div className="qr-code-field-wrap">
      <div className="qr-code-field-first-row">
        <div className="qr-code-serial-number-input">
          <div className="xlFile-selection-wrap">
            <CustomInput
              name="singleDeviceSerialNumber"
              inputLabel=""
              type="text"
              placeholder="Enter Device Number"
              value={qrDetails.singleDeviceSerialNumber}
              onChangeValue={handleChangeFunction}
              valueRequired
              readOnly={!!(qrDetails.xlFile?.length && qrDetails.xlFile?.length > 0)}
            />
            <span />
          </div>
          <p className="select-either-or">or</p>
          <div className="xlFile-selection-wrap">
            <CustomFileInput
              name="xlFile"
              inputLabel=""
              placeholder="Choose file"
              selectedFiles={qrDetails.xlFile}
              onChangeValue={handleChangeFunction}
              unselectHandler={handleRemoveFile}
              acceptedFileType=".xls,.xlsx"
              valueRequired
              disabled={qrDetails.singleDeviceSerialNumber !== ''}
            />
            <span className='warning'>Column header for Device Serial Number: DeviceSn</span>
          </div>
        </div>
        <div className="qr-code-logo-input">
          <CustomFileInput
            name="qrLogo"
            inputLabel=""
            placeholder="Add logo"
            selectedFiles={qrDetails.qrLogo}
            onChangeValue={handleChangeFunction}
            unselectHandler={handleRemoveFile}
            acceptedFileType="image/*"
            valueRequired
          />
          <CustomInput
            name="title"
            inputLabel=""
            type="text"
            placeholder="Brand name"
            value={qrDetails.title.text}
            onChangeValue={handleChangeFunction}
            valueRequired
          />
          <CustomInput
            name="size"
            inputLabel=""
            type="number"
            placeholder="Qr size"
            value={qrDetails.size}
            onChangeValue={handleChangeFunction}
            valueRequired
          />
        </div>
      </div>
      <div className="qr-code-text-area-wrap">
        <CustomInput
          name="description"
          inputLabel=""
          type="text"
          placeholder="Add notes..."
          value={qrDetails.description.text}
          onChangeValue={handleChangeFunction}
          valueRequired
        />
      </div>
    </div>
  );
}

export default QrCodeInputField;
