import { configureStore } from '@reduxjs/toolkit';

import AccountManagementReducer from './slices/AccountManagementSlice';
import ApprovedPricingReducer from './slices/ApprovedPricingSlice';
import ApprovedStationManagementReducer from './slices/ApprovedStationManagementSlice';
import EndConsumerManagementReducer from './slices/EndConsumerManagementSlice';
import NotificationMessageReducer from './slices/NotificationManagementSlice';
import PaymentManagementReducer from './slices/PaymentManagementSlice';
import PendingPricingReducer from './slices/PendingPricingSlice';
import RejectedPricingReducer from './slices/RejectedPricingSlice';
import RejectedStationManagementReducer from './slices/RejectedStationManagementSlice';
import ReportManagementReducer from './slices/ReportManagementSlice';
import RevenueManagementAccountReducer from './slices/RevenueManagementAccountSlice';
import RevenueManagementStationReducer from './slices/RevenueManagementStationSlice';
import RevenueSharingReducer from './slices/RevenueSharingSlice';
import RoleManagementReducer from './slices/RolesManagementSlice';
import UnapprovedStationManagementReducer from './slices/UnapprovedStationManagementSlice';
import UserManagementReducer from './slices/UsersManagementSlice';
import UtilityManagementReducer from './slices/UtilityProviderManagementSlice';

// Store configure with the reducer
export const store = configureStore({
  reducer: {
    accounts: AccountManagementReducer,
    utility: UtilityManagementReducer,
    endConsumer: EndConsumerManagementReducer,
    notification: NotificationMessageReducer,
    payments: PaymentManagementReducer,
    users: UserManagementReducer,
    roles: RoleManagementReducer,
    approvedStation: ApprovedStationManagementReducer,
    unapprovedStation: UnapprovedStationManagementReducer,
    rejectedStation: RejectedStationManagementReducer,
    reports: ReportManagementReducer,
    approvedPricing: ApprovedPricingReducer,
    pendingPricing: PendingPricingReducer,
    rejectedPricing: RejectedPricingReducer,
    revenueAccount: RevenueManagementAccountReducer,
    revenueStation: RevenueManagementStationReducer,
    revenueSharing: RevenueSharingReducer,
  },
});

// RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
