import React, { useEffect, useState } from 'react';

import {
  UserAddInterFace,
  UserInterFace,
  UserParameters,
} from '../../../interface/users-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import { fetchAllListRoles } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import CustomPhoneInput from '../../../shared-components/custom-input/CustomPhoneInput';
import SelectFiled from '../../../shared-components/select-field/SelectField';
import {
  emailValidation,
  passwordValidation,
  phoneNumberValidation,
  stringValidation,
} from '../../../shared-functions/Validation';
import userApi from '../../../utils/api/users';

/**
 * Add User page function component
 */
function AddUser({
  closeModal,
  currentDate = {
    id: '',
    _id: '',
    name: '',
    date: '',
    accountId: '',
    countryCode: '',
    email: '',
    phoneNumber: '',
    role: '',
    status: false,
    createdAt: '',
  },
  completedTask,
}: {
  closeModal: () => void;
  currentDate?: UserInterFace;
  completedTask: (message: string, type: string) => void;
}) {
  const dispatch = useAppDispatch();
  const { rolesList } = useAppSelector((state) => state.roles);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserAddInterFace>({
    role: currentDate.role || '',
    name: currentDate.name || '',
    email: currentDate.email || '',
    password: '',
    rePassword: '',
    phone: currentDate?.phoneNumber || '',
    countryCode: currentDate?.countryCode || '1',
  });

  /**
   * Fetch Users list function
   */
  const fetchData = () => {
    dispatch(fetchAllListRoles());
  };

  console.log(rolesList);

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setUserDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    setShowFormErrors(true);
    let errorMessage = false;
    if (!currentDate.id) {
      if (
        userDetails.role === '' ||
        !stringValidation(1, 100, userDetails.name) ||
        userDetails.email === '' ||
        !passwordValidation(userDetails.password) ||
        userDetails.phone === '' ||
        userDetails.countryCode === '' ||
        !emailValidation(userDetails.email) ||
        !phoneNumberValidation(userDetails.phone) ||
        userDetails.rePassword !== userDetails.password
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: UserParameters = {
          name: userDetails.name,
          role: userDetails.role,
          email: userDetails.email.toLowerCase(),
          password: userDetails.password,
          phoneNumber: userDetails.phone,
          countryCode: userDetails.countryCode,
        };
        userApi
          .createUser(request)
          .then(() => {
            setDisabled(false);
            completedTask('User Added Successfully', 'user');
          })
          .catch((error) => {
            setDisabled(false);
            dispatch(
              setNotificationMessage({
                message: error?.response?.data?.message,
                status: true,
                type: 'error',
                code: error?.response?.data?.code,
              }),
            );
          });
      }
    } else {
      if (
        userDetails.role === '' ||
        !stringValidation(1, 100, userDetails.name) ||
        userDetails.email === '' ||
        userDetails.phone === '' ||
        userDetails.countryCode === '' ||
        !emailValidation(userDetails.email) ||
        !phoneNumberValidation(userDetails.phone)
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: UserParameters = {
          name: userDetails.name,
          role: userDetails.role,
          email: userDetails.email.toLowerCase(),
          phoneNumber: userDetails.phone,
          countryCode: userDetails.countryCode,
        };
        userApi
          .editUser(currentDate.id, request)
          .then(() => {
            setDisabled(false);
            completedTask('User Updated Successfully', 'user');
          })
          .catch((error) => {
            setDisabled(false);
            dispatch(
              setNotificationMessage({
                message: error?.response?.data?.message,
                status: true,
                type: 'error',
                code: error?.response?.data?.code,
              }),
            );
          });
      }
    }
  };

  useEffect(() => {
    if (rolesList.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <div className="add-account-form">
      <div className="form-body">
        <div className="form-field">
          <SelectFiled
            name="role"
            inputLabel="Role"
            selectedValue={userDetails.role}
            placeholder="Select Role"
            onChangeValue={handleInputChange}
            valueRequired
            error={showFormErrors && userDetails.role === ''}
            errorMessage="Role is Required"
            options={rolesList}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Name"
            type="text"
            placeholder="Enter the name"
            value={userDetails.name}
            onChangeValue={handleInputChange}
            valueRequired
            error={showFormErrors && !stringValidation(1, 100, userDetails.name)}
            errorMessage="Name is Required"
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="email"
            inputLabel="Email"
            type="email"
            placeholder="Enter the email"
            value={userDetails.email}
            onChangeValue={handleInputChange}
            valueRequired
            error={
              showFormErrors && (userDetails.email === '' || !emailValidation(userDetails.email))
            }
            errorMessage="Email is Required or valid"
          />
        </div>
        <div className="form-field">
          <CustomPhoneInput
            name="phone"
            inputLabel="Phone Number"
            type="number"
            placeholder="Enter Phone Number"
            value={userDetails.phone}
            value1={userDetails.countryCode}
            onChangeValue={handleInputChange}
            valueRequired
            error={
              showFormErrors &&
              (userDetails.phone === '' || !phoneNumberValidation(userDetails.phone))
            }
            errorMessage="Phone Number is Required or Invalid"
          />
        </div>
        {!currentDate.id && (
          <>
            <div className="form-field">
              <CustomInput
                name="password"
                inputLabel="Password"
                type="password"
                placeholder="Enter Password"
                value={userDetails.password}
                onChangeValue={handleInputChange}
                valueRequired
                error={showFormErrors && !passwordValidation(userDetails.password)}
                errorMessage="Password is Required"
              />
            </div>
            <div className="form-field">
              <CustomInput
                name="rePassword"
                inputLabel="Re-Enter Password"
                type="password"
                placeholder="Enter Password"
                value={userDetails.rePassword}
                onChangeValue={handleInputChange}
                valueRequired
                error={
                  showFormErrors &&
                  (userDetails.rePassword === '' || userDetails.password !== userDetails.rePassword)
                }
                errorMessage="Password and Re-password is also same"
              />
            </div>
          </>
        )}
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Submit"
              buttonType="submit"
              disabled={disabled}
              loading={disabled}
              onClickFunc={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AddUser.defaultProps = {
  currentDate: {},
};

export default AddUser;
