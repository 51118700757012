import React, { useState } from 'react';

import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import profile from '../../../assets/images/profiles/profile.jpg';
// import { AccountsInterFace } from '../../../interface/account-management.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';

interface ReasonDetailInterface {
  reason: string;
}

interface DeleteAccountInterface {
  accountDetails?: any;
  closeModal: (message: string) => void;
  conformSubmit: (value: any) => void;
  buttonLabel: string;
}
/**
 * Delete Account function component
 */
function DeleteAccount({
  accountDetails,
  closeModal,
  conformSubmit,
  buttonLabel,
}: DeleteAccountInterface) {
  const [reasonDetails, setReasonDetails] = useState<ReasonDetailInterface>({
    reason: '',
  });

  /**
   * Input filed handle change function
   */
  const handleInput = (name: string, value: string | number) =>
    setReasonDetails({
      ...reasonDetails,
      [name]: value,
    });

  return (
    <div className="add-account-form">
      <div className="view-body">
        <div className="profile-details">
          <div className="profile-image">
            <img src={profile} alt="profile" />
          </div>
          <div className="profile-user">
            <div className="profile-title-1">{accountDetails?.name}</div>
            <div className="profile-title-2">{accountDetails?.email}</div>
          </div>
        </div>
        <div className="form-field">
          <CustomInput
            type="text"
            placeholder="Enter the reason"
            value={reasonDetails.reason}
            name="reason"
            onChangeValue={handleInput}
            inputLabel="Enter the valid reason"
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={() => closeModal('')} />
          </div>
          <div className="table-button-item">
            <MyButton
              label={buttonLabel}
              buttonType="error"
              svgBackIcon={<Delete />}
              onClickFunc={() => conformSubmit({ ...accountDetails, ...reasonDetails })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DeleteAccount.defaultProps = {
  accountDetails: {},
};

export default DeleteAccount;
