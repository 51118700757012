/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  DynamicPricingInitialProperties,
  DynamicPricingProperties,
  FetchPricingProperties,
  RequestPricingInterface,
} from '../../interface/dynamic-pricing.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import dynamicPricing from '../../utils/api/dynamic-pricing';

// a thunk to fetch users
export const fetchRejectedPricing = createAsyncThunk<
  FetchPricingProperties,
  RequestPricingInterface
>('rejected-pricing/fetchRejectedPricing', async (data: RequestPricingInterface, { dispatch }) => {
  try {
    const response = await dynamicPricing.fetchDynamicPricing(
      removeEmptyKeys({ ...data, status: 'rejected' }),
    );
    return response.data;
  } catch (error: any) {
    dispatch({
      type: 'notification/setNotificationDetails',
      payload: {
        type: 'error',
        notificationDetails: {
          statusCode: error?.response?.data?.code || 200,
          message: error?.response?.data?.message || 'Something went wrong!',
        },
      },
    });
    throw error;
  }
});

// Initial state
const initialState: DynamicPricingInitialProperties = {
  pricing: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const rejectedPricingSlice = createSlice({
  name: 'rejected-pricing',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setRejectedFilterData: (state, action) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchRejectedPricing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRejectedPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.pricing = action.payload?.results?.length
          ? action.payload.results?.map((eachPricing: DynamicPricingProperties) => ({
              ...eachPricing,
              createdAt: eachPricing.createdAt
              ? ConvertUTCtoDate(eachPricing?.createdAt)
              : '',
            }))
          : [];
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchRejectedPricing.rejected, (state) => {
        state.loading = false;
      });
  },
});
// Export the actions
export const { setRejectedFilterData } = rejectedPricingSlice.actions;
// Export the reducer
export default rejectedPricingSlice.reducer;
