import React, { useEffect, useState } from 'react';

import { ReactComponent as Delete } from '../../../assets/icons/delete-icon.svg';
import { ReactComponent as EditPencilCard } from '../../../assets/icons/edit-pencil.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-2-icon.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import {
  AccountFilterInterFace,
  AccountsInterFace,
  RequestAccountInterface,
} from '../../../interface/account-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import {
  fetchAllAccounts,
  setUtilityAccountFilterData,
} from '../../../redux/slices/UtilityProviderManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomRadioButton from '../../../shared-components/custom-radio-button/CustomRadioButton';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ConfirmBox from '../../../shared-components/modal-box/ConfirmBox';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';
import ConvertUTCtoDate from '../../../shared-functions/ConvertUTCtoDate';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import accountApi from '../../../utils/api/utility-provider';

import AddAccount from './add-account';
import FilterAccount from './filter-account';
import ViewAccount from './view-account';

interface ModalInterface {
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
  notification: boolean;
}

/**
 * Facility account list
 * @param param0
 * @returns
 */
function UtilityProviderList({
  currentPage,
  setCurrentPage,
}: {
  currentPage: string;
  setCurrentPage: (value: string) => void;
}) {
  const dispatch = useAppDispatch();
  const { accounts, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.utility,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof AccountsInterFace,
      sortAllow: true,
    },
    {
      label: 'User Name',
      accessorKey: 'name' as keyof AccountsInterFace,
      sortAllow: true,
    },
    {
      label: 'Email Id',
      accessorKey: 'email' as keyof AccountsInterFace,
      sortAllow: true,
    },
    {
      label: 'Address',
      accessorKey: 'address' as keyof AccountsInterFace,
      sortAllow: true,
    },
    {
      label: 'Account Type',
      accessorKey: 'accountType' as keyof AccountsInterFace,
      sortAllow: true,
    },
  ];
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<String>('');
  const [viewFields, setViewFields] = useState<
    { label: string; accessorKey: string; allow: boolean }[]
  >([
    { label: 'Date', accessorKey: 'createdAt', allow: true },
    { label: 'Account Id', accessorKey: 'accountCustomId', allow: true },
    { label: 'Verification Status', accessorKey: 'status', allow: true },
    { label: 'Account Type', accessorKey: 'accountType', allow: true },
  ]);

  const [modalView, setModalView] = useState<ModalInterface>({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
    notification: false,
  });

  /**
   * Fetch the account details in redus
   */
  const fetchData = (data: RequestAccountInterface) => {
    if (!loading) {
      dispatch(fetchAllAccounts(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = (message: string) => {
    if (message) {
      setOpenStatus(true);
      setModalView({
        title: '',
        header: false,
        content: (
          <SuccessNotification
            title={message}
            description=""
            notificationCloseTimer={3000}
            onNotificationClose={() => {
              setOpenStatus(false);
            }}
          />
        ),
        rightCorner: '',
        notification: true,
      });
    } else {
      setOpenConfirm(false);
      setOpenStatus(false);
    }
  };

  /**
   * Add account function
   */
  const addAccount = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: true,
      content: <AddAccount closeModal={closeModal} />,
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Edit account function
   */
  const editAccount = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      setOpenStatus(true);
      setModalView({
        title: 'Edit Account',
        header: true,
        content: <AddAccount closeModal={closeModal} currentData={accountDetails} />,
        rightCorner: '',
        notification: false,
      });
    }
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageAndLimitChange = (value: RequestAccountInterface) => {
    if (value.sortField) {
      dispatch(
        setUtilityAccountFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchData({ ...filter, ...value });
  };

  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: AccountFilterInterFace) => {
    dispatch(setUtilityAccountFilterData(filterData));
    fetchData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setOpenStatus(false);
  };
  /**
   * Handle clear filter data function
   * @param value
   */
  const handleClearFilter = () => {
    dispatch(setUtilityAccountFilterData({}));
    fetchData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * handleAvailabilityChange
   */
  const handleAvailabilityChange = (name: string, value: string) => {
    setCurrentPage(value);
  };

  /**
   * Account delete submit function
   */
  const deleteFunction = (currentData: String) => {
    setOpenConfirm(true);
    setDeleteId(currentData);
  };

  /**
   * Account details view function
   */
  const viewFunction = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      setOpenStatus(true);
      setModalView({
        title: '',
        header: false,
        content: <ViewAccount accountDetails={accountDetails} refersData={closeModal} />,
        rightCorner: '',
        notification: false,
      });
    }
  };

  /**
   * Delete confirm function
   */
  const deleteConfirmFunction = () => {
    accountApi.deleteAccount(deleteId).then(() => {
      fetchData({
        page: DefaultApiParameters.page,
        limit,
      });
      closeModal('Utility Provider Account Deleted Successfully...');
      setOpenConfirm(false);
    });
  };

  /**
   * Account filter view function
   */
  const accountFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: (
        <FilterAccount
          closeModal={closeModal}
          oldValue={filter}
          handleSubmitFilter={handleSubmitFilter}
        />
      ),
      rightCorner: '',
      notification: false,
    });
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    accountApi
      .exportData(filter)
      .then((response) => {
        // Convert data to CSV format
        const headers = viewFields.filter((field) => field.allow).map((field) => field.label);
        const fields = viewFields.filter((field) => field.allow).map((field) => field.accessorKey);
        const rows = response.data.map((row: AccountsInterFace) => [
          fields.includes('createdAt') && `${ConvertUTCtoDate(row?.createdAt || '')}-`,
          fields.includes('accountCustomId') && row.accountCustomId,
          fields.includes('status') && row.submission?.status,
          fields.includes('accountType') && 'Facility Owner',
        ]);
        const filteredArray = rows.map((row: any[]) => row.filter((item: any) => item !== false));
        if (type === 'csv') {
          exportCSV(headers, filteredArray, 'account-list.csv');
        } else {
          exportPDF(headers, filteredArray, 'account-list.pdf');
        }
        setExportLoader(false);
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Handle the view fields
   * @param fields
   */
  const hideAndShowFields = (fields: string) => {
    if (fields === 'all-hide') {
      setViewFields((previous) => previous.map((value) => ({ ...value, allow: false })));
    } else if (fields === 'all-show') {
      setViewFields((previous) => previous.map((value) => ({ ...value, allow: true })));
    } else {
      setViewFields((previous) => {
        const output = [...previous];
        const index = output.findIndex((value) => value.accessorKey === fields);
        if (index !== -1) {
          output[index].allow = !output[index].allow;
        }
        return [...output];
      });
    }
  };

  useEffect(() => {
    if (accounts.length === 0) {
      if (filter)
        fetchData({
          ...filter,
          limit,
          page,
        });
    }
  }, []);
  return (
    <div>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
        notification={modalView.notification}
      />
      <ConfirmBox
        open={openConfirm}
        title="Are you sure want to delete this Utility Provider?"
        subTitle="Warning!: Deleting Utility Provider is permanent and cannot be
undone. All the data and history will be lost.
Are you sure you want to proceed?"
        conformSubmit={deleteConfirmFunction}
        closeModal={() => {
          setOpenConfirm(false);
        }}
      />
      <CustomTable
        header={column}
        data={accounts}
        limit={limit}
        page={page}
        totalPages={totalPages}
        totalResults={totalResults}
        loading={loading}
        serialNo
        pageLimitChange={pageAndLimitChange}
        actionButton
        height={window.innerHeight >= 700 ? 347 : 327}
        filter={filter}
        filterFunction={accountFilterFunction}
        filterClearFunction={handleClearFilter}
        sizeChangeButtonRequired
        actionDropdown
        viewFieldAllow={false}
        viewFields={viewFields}
        hideAndShowFields={hideAndShowFields}
        liftTopContent={
          <div className="revenue-management-toggle-wrap">
            <CustomRadioButton
              name=""
              value="account"
              label="Facility Account"
              selected={currentPage === 'account'}
              onChangeFunc={handleAvailabilityChange}
            />
            <CustomRadioButton
              name=""
              value="utility"
              label="Utility Provider"
              selected={currentPage === 'utility'}
              onChangeFunc={handleAvailabilityChange}
            />
          </div>
        }
        actionDropDownList={[
          {
            content: (
              <div className="flex-row">
                <div className="text-primary">
                  <EditPencilCard />
                </div>{' '}
                Edit
              </div>
            ),
            function: editAccount,
            allow: true,
          },
          {
            content: (
              <div className="flex-row">
                <div className="text-primary">
                  <EyeIcon />
                </div>{' '}
                View
              </div>
            ),
            function: viewFunction,
            allow: true,
          },
          {
            content: (
              <div className="flex-row">
                <div className="text-danger">
                  <Delete />
                </div>{' '}
                Delete
              </div>
            ),
            function: deleteFunction,
            allow: true,
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <MyButton
                label="Add Account"
                buttonType="submit"
                onClickFunc={addAccount}
                svgIcon={<Plus />}
              />
            </div>
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={accounts.length === 0}
                loader={exportLoader}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default UtilityProviderList;
