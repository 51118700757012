import React, { useEffect, useRef } from 'react';

// import { ReactComponent as Calender } from '../../../assets/icons/calender.svg';
// import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as CloseCircle } from '../../../assets/icons/close-circle.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { useAppSelector } from '../../../redux/hooks';
import ConvertUTCtoHHMM from '../../../shared-functions/ConvertUTCtoHHMM';
import FormatUtcString from '../../../shared-functions/FormatUtcString';

interface AddRoleInterface {
  closeModal: () => void;
  activeLogModal: boolean;
  position: any;
}
/**
 * Role Add page function component
 */
function ActiveLogCard({ activeLogModal, closeModal, position }: AddRoleInterface) {
  const { activityLogs } = useAppSelector((state) => state.endConsumer);
  const reference = useRef<HTMLDivElement | null>(null);

  console.log(activityLogs);

  /**
   * Handle the close th drop down function
   * @param event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      className={`active-log-card-warp ${activeLogModal && 'open'}`}
      ref={reference}
      style={position}>
      <div className="active-log-card-body">
        <div className="close-icon" onClick={closeModal} role="presentation">
          <CloseCircle />
        </div>
        <div className="active-log-content">
          <div className="active-logs-list">
            {activityLogs.map((activityLog, index) => (
              <div className="active-loge-item">
                <div className={`icon ${index !== activityLogs.length - 1 && 'line'}`}>
                  <DotCircle />
                </div>
                <div className="details">
                  <div className="calender-icon">
                    {/* {theme === 'light' ? <Calender /> : <CalenderDark />} */}
                  </div>
                  <div className="calender-details">
                    <div className="date">{activityLog?.date}</div>
                    <div className="time-list">
                      {activityLog?.logs?.map((value) => (
                        <div className="time-item">
                          <div className="title">
                            {FormatUtcString(ConvertUTCtoHHMM(value?.createdAt))}
                          </div>
                          <div className="sub-title">
                            {value?.title}
                            {/* {value.status === 'unread' ? <div className="text-danger"><RemoveIcon /></div> : <div className="text-success">{ theme === 'light' ? <CheckCircle /> : <CheckCircleDark />}</div>} */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {activityLogs.length === 0 && (
              <div className="not-found">
                <div className="title">No Activity logs</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveLogCard;
