import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import Header from '../../shared-components/header/Header';
import Sidebar from '../../shared-components/sidebar/Sidebar';
import ToastMessage from '../../shared-components/toast-message/ToastMessage';
import AccountManagement from '../account-management';
import ChargingStationManagement from '../charging-station';
import Dashboard from '../dashboard';
import DeviceQrManagement from '../device-qr-management';
import DynamicPricing from '../dynamic-pricing';
import EndConsumerManagement from '../end-consumer-management';
import Login from '../login';
import ForgetPassword from '../login/components/forgot-password';
import NewPasswordChange from '../login/components/new-password-change';
import OTPVerification from '../login/components/otp-verification';
import PaymentManagement from '../payments';
import ReportManagement from '../reports';
import RevenueManagement from '../revenue-management';
import UserManagement from '../user-management';

import AuthGuard from './components/AuthGuard';

// import AuthGuard from './components/AuthGuard';

/**
 * Wrapper Route function component
 */
function WrapperRoute() {
  return (
    <div className="ev-layout-wrap">
      <div className="ev-sidebar">
        <Sidebar />
      </div>
      <div className="ev-content-wrap">
        <Header />
        <div className="ev-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

/**
 * Router page function component
 */
function Router() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notificationMessage, notificationType, notificationStatus, notificationCode } =
    useAppSelector((state) => state.notification);
  const routes = [
    {
      path: 'dashboard',
      children: [
        {
          id: 1,
          path: '',
          element: <Dashboard />,
          permission: ['dashboard'],
        },
      ],
    },
    {
      path: 'account',
      children: [
        {
          id: 2,
          path: '',
          element: <AccountManagement />,
          permission: ['accounts_management'],
        },
      ],
    },
    {
      path: 'charging-station',
      children: [
        {
          id: 3,
          path: ':currentPage',
          element: <ChargingStationManagement />,
          permission: ['charging_station'],
        },
      ],
    },
    {
      path: 'reports',
      children: [
        {
          id: 4,
          path: '',
          element: <ReportManagement />,
          permission: ['reporting_tools'],
        },
      ],
    },
    {
      path: 'user-management',
      children: [
        {
          id: 5,
          path: ':currentPage',
          element: <UserManagement />,
          permission: ['user_management'],
        },
      ],
    },
    {
      path: 'end-consumer',
      children: [
        {
          id: 6,
          path: '',
          element: <EndConsumerManagement />,
          permission: ['end_consumer_management'],
        },
      ],
    },
    {
      path: 'payments',
      children: [
        {
          id: 7,
          path: '',
          element: <PaymentManagement />,
          permission: ['payments'],
        },
      ],
    },
    {
      path: 'revenue/:page',
      children: [
        {
          id: 8,
          path: '',
          element: <RevenueManagement />,
          permission: ['wallet_revenue_sharing'],
        },
      ],
    },
    {
      path: 'dynamic-pricing/:currentPage',
      children: [
        {
          id: 8,
          path: '',
          element: <DynamicPricing />,
          permission: ['dynamic_pricing'],
        },
      ],
    },
    {
      path: 'device-qr-management',
      children: [
        {
          id: 8,
          path: '',
          element: <DeviceQrManagement />,
          permission: [],
        },
      ],
    },
  ];

  useEffect(() => {
    if (notificationCode === 401) {
      navigate('/login');
    }
  }, [notificationCode]);
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/otp-verification" element={<OTPVerification />} />
        <Route path="/new-password" element={<NewPasswordChange />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/ev-admin" element={<WrapperRoute />}>
          {routes.map((route) => (
            <Route path={route.path}>
              {route.children.map((subRoute) => (
                <Route
                  key={subRoute?.id}
                  path={subRoute?.path}
                  element={
                    <AuthGuard permission={subRoute?.permission} Component={subRoute?.element} />
                  }
                />
              ))}
            </Route>
          ))}
        </Route>
      </Routes>
      <ToastMessage
        open={notificationStatus}
        type={notificationType}
        message={notificationMessage || 'Something went wrong!'}
        onClose={() =>
          dispatch(
            setNotificationMessage({
              status: false,
              message: '',
              type: 'error',
              code: 0,
            }),
          )
        }
        onCloseTimer={3000}
      />
    </>
  );
}

export default Router;
