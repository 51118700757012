import React, { useState } from 'react';

import FacilityAccountList from './components/facility-account-list';
import UtilityProviderList from './components/utility-provider-list';

/**
 * Account Management function Component
 */
function AccountManagement() {
  const [currentPage, setCurrentPage] = useState<string>('account');

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="container">
        <div className="header-title">
          <h2>Account Management</h2>
        </div>
        <div className="body-container">
          <div className="table-content">
            {currentPage === 'account' && <FacilityAccountList setCurrentPage={setCurrentPage} currentPage={currentPage} />}
            {currentPage === 'utility' && <UtilityProviderList setCurrentPage={setCurrentPage} currentPage={currentPage} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountManagement;
