/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  APIUserResponseInterface,
  FilterUserInterFace,
  RequestUserInterFace,
  UserActivityLogs,
  UserInitialProperties,
  UserResponseInterFace,
} from '../../interface/users-management.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import ConvertUTCtoHHMM from '../../shared-functions/ConvertUTCtoHHMM';
import { arrayGroupBy, removeEmptyKeys } from '../../shared-functions/DateTime';
import FormatUtcString from '../../shared-functions/FormatUtcString';
import userApi from '../../utils/api/users';

import { setNotificationMessage } from './NotificationManagementSlice';

// a thunk to fetch users
export const fetchAllUsers = createAsyncThunk<APIUserResponseInterface, RequestUserInterFace>(
  'user-management/fetchAllUsers',
  async (data: RequestUserInterFace, { dispatch }) => {
    const response = await userApi
      .fetchUsers(removeEmptyKeys(data))
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return {
      ...response.data,
      results: response.data.results.map((roleData: UserResponseInterFace) => ({
        ...roleData,
        createdAt: ConvertUTCtoDate(roleData.createdAt),
        roleName: roleData?.role?.name,
        role: roleData?.role?._id,
        id: roleData._id,
      })),
    };
  },
);
// Fetch session user
export const fetchUserActivityLogs = createAsyncThunk<UserActivityLogs[], String>(
  'user-management/fetchUserActivityLogs',
  async (id: String, { dispatch }) => {
    try {
      const response = await userApi.fetchUserActivityLogs(id);
      return response.data.map((userData: any) => ({
        ...userData,
        date: ConvertUTCtoDate(userData.createdAt),
        time: FormatUtcString(ConvertUTCtoHHMM(userData.createdAt)),
        id: userData._id,
      }));
    } catch (error: any) {
      dispatch({
        type: 'notification/setNotificationDetails',
        payload: {
          type: 'error',
          notificationDetails: {
            statusCode: error?.response?.data?.code || 200,
            message: error?.response?.data?.message || 'Something went wrong!',
          },
        },
      });
      throw error;
    }
  },
);

// Initial state
const initialState: UserInitialProperties = {
  users: [],
  userActiveLogs: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  loggingLoading: false,
  filter: {},
};

// Create the slice
export const userSlice = createSlice({
  name: 'users-management',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setUserFilterData: (
      state: UserInitialProperties,
      action: PayloadAction<FilterUserInterFace>,
    ) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<UserInitialProperties>) => {
    builder
      .addCase(fetchAllUsers.pending, (state: UserInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllUsers.fulfilled,
        (state: UserInitialProperties, action: PayloadAction<APIUserResponseInterface>) => {
          state.loading = false;
          state.users = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllUsers.rejected, (state: UserInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
    builder
      .addCase(fetchUserActivityLogs.pending, (state) => {
        state.loggingLoading = true;
      })
      .addCase(
        fetchUserActivityLogs.fulfilled,
        (state, action: PayloadAction<UserActivityLogs[]>) => {
          state.loggingLoading = false;
          state.userActiveLogs = Object.entries(arrayGroupBy(action.payload, 'date')).map(
            (value) => ({
              logs: value[1].reverse(),
              date: value[0],
            }),
          );
        },
      )
      .addCase(fetchUserActivityLogs.rejected, (state) => {
        state.loggingLoading = false;
      });
  },
});

// Export the actions
export const { setUserFilterData } = userSlice.actions;
// Export the reducer
export default userSlice.reducer;
