import React, { useEffect, useState } from 'react';

import { ReactComponent as ActivityIcon } from '../../../assets/icons/activety-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg';
import { ReactComponent as EditPencil } from '../../../assets/icons/edit-pencil.svg';
import { ReactComponent as GridList } from '../../../assets/icons/grid-list.svg';
import { ReactComponent as TableList } from '../../../assets/icons/table-list.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/tables/dot-circle.svg';
import NoDataFound from '../../../assets/images/no-data-found.png';
import { brandIcons } from '../../../config/branding';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import {
  FilterUserInterFace,
  RequestUserInterFace,
  UserInterFace,
  UserResponseInterFace,
} from '../../../interface/users-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import { fetchAllUsers, fetchUserActivityLogs, setUserFilterData } from '../../../redux/slices/UsersManagementSlice';
import BatteryLoader from '../../../shared-components/battery-loader/BatteryLoader';
import MyButton from '../../../shared-components/button/Button';
import CardBox from '../../../shared-components/card-box/CardBox';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import Pagination from '../../../shared-components/pagination/Pagination';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';
import TopHeaderButtons from '../../../shared-components/top-header-buttons/TopHeaderButtons';
import ConvertUTCtoDate from '../../../shared-functions/ConvertUTCtoDate';
import { splitArrayIntoChunks } from '../../../shared-functions/DateTime';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import userApi from '../../../utils/api/users';

import ActiveLogCard from './active-log-card';
import AddUser from './add-user';
import DeleteUser from './delete-user';
import FilterUser from './filter-user';
import UserCardBody from './user-card-body';

/**
 * User Management page function component
 */
function UsersList() {
  const dispatch = useAppDispatch();
  const { users, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.users,
  );

  const [activeLogModal, setActiveLogModal] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  const [position, setPosition] = useState<any>({});
  const [fullWidth, setFullWidth] = useState<any>({});
  const [modalView, setModalView] = useState({
    status: false,
    title: 'Add Account',
    header: true,
    content: <div />,
    rightCorner: '',
    notification: false,
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Fetch Users list function
   */
  const fetchData = (data: RequestUserInterFace) => {
    if (!loading) {
      dispatch(fetchAllUsers(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: false,
      content: <div />,
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestUserInterFace) => {
    if (value.sortField) {
      dispatch(
        setUserFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchData({
      ...filter,
      status: filter.status ? filter.status === 'active' : undefined,
      ...value,
    });
  };

  /**
   * Completed the update task
   * @param message
   */
  const completedTask = (message: string) => {
    fetchData({
      limit: DefaultApiParameters.limit,
      page: DefaultApiParameters.page,
    });
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <SuccessNotification
          title={message}
          description=""
          notificationCloseTimer={3000}
          onNotificationClose={closeModal}
        />
      ),
      rightCorner: '',
      notification: true,
    });
  };

  /**
   * The delete the user confirm function
   */
  const deleteConfirmFunction = (accountId: String) => {
    userApi
      .deleteUser(accountId)
      .then(() => {
        completedTask('User Deleted Successfully...');
      })
      .catch((error) => {
        closeModal();
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * User Delete function
   */
  const deleteFunction = (currentData: String) => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <DeleteUser
          title="Are you sure want to delete this user?"
          subTitle="Deleting your account is permanent and cannot be undone. All the data, settings, and transaction history will be lost. Are you sure you want to proceed?"
          closeModal={() => {
            closeModal();
          }}
          submitButton={
            <MyButton
              label="Delete"
              buttonType="error"
              onClickFunc={() => deleteConfirmFunction(currentData)}
              svgIcon={<DeleteIcon />}
            />
          }
        />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Edit the user function
   * @param currentDate
   */
  const editUser = (currentDate: String) => {
    setModalView({
      status: true,
      title: 'Edit User',
      header: true,
      content: (
        <AddUser
          closeModal={closeModal}
          currentDate={users.find((user) => user.id === currentDate)}
          completedTask={completedTask}
        />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: FilterUserInterFace) => {
    dispatch(setUserFilterData(filterData));
    fetchData({
      ...filterData,
      status: filterData.status ? filterData.status === 'active' : undefined,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };
  /**
   * Handle clear filter data function
   * @param value
   */
  const handleClearFilter = () => {
    dispatch(setUserFilterData({}));
    fetchData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Add Account',
      header: false,
      content: (
        <FilterUser
          closeModal={closeModal}
          handleSubmitFilter={handleSubmitFilter}
          oldValue={filter}
        />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * User details view function
   */
  const viewFunction = (currentData: String, event?: React.MouseEvent) => {
    if (event) {
      setActiveLogModal(true);
      if (event.clientY > 320) {
        setPosition({ top: `${event.clientY - 320}px`, '--card-left-position': '5%' });
      } else {
        setPosition({
          top: `${event.clientY + 40}px`,
          '--card-bottom-position': '91%',
          '--card-left-position': '5%',
        });
      }
      dispatch(fetchUserActivityLogs(currentData));
    }
  };

  /**
   * User details view function
   */
  const viewCardActiveFunction = (
    currentData: String,
    userIndex: number,
    event?: React.MouseEvent,
  ) => {
    if (event) {
      let cardLeftPosition = '13%';
      let gridRowStart = 1;
      let rowCount = 4;
      const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      const array2 = [
        [0, 4, 8],
        [1, 5, 9],
        [2, 6],
        [3, 7],
      ];
      if (window.innerWidth > 1571) {
        rowCount = 4;
      } else if (window.innerWidth > 1333) {
        rowCount = 3;
      } else if (window.innerWidth > 840) {
        rowCount = 2;
      } else {
        rowCount = 1;
      }
      const chunks = splitArrayIntoChunks(array, rowCount);
      cardLeftPosition = `${(100 * userIndex) / rowCount / 2}%`;
      gridRowStart = chunks.findIndex((value) => value.includes(userIndex)) + 1;
      cardLeftPosition = `${(100 * gridRowStart) / rowCount / 2}%`;

      if (rowCount === 1) {
        cardLeftPosition = '50%';
      } else if (rowCount === 2) {
        cardLeftPosition = userIndex % 2 !== 0 ? '75%' : '25%';
      } else if (rowCount === 3) {
        if (userIndex && [0, 3, 6, 9].includes(userIndex)) {
          cardLeftPosition = '20%';
        } else if (userIndex && [1, 4, 7].includes(userIndex)) {
          cardLeftPosition = '50%';
        } else if (userIndex && [2, 5, 8].includes(userIndex)) {
          cardLeftPosition = '80%';
        }
      } else if (rowCount === 4) {
        if (userIndex && array2[0].includes(userIndex)) {
          cardLeftPosition = '13%';
        } else if (userIndex && array2[1].includes(userIndex)) {
          cardLeftPosition = '37.5%';
        } else if (userIndex && array2[2].includes(userIndex)) {
          cardLeftPosition = '63.5%';
        } else if (userIndex && array2[3].includes(userIndex)) {
          cardLeftPosition = '86%';
        }
      }
      setPosition({
        '--card-left-position': cardLeftPosition,
        top: '0px',
        left: '0',
        position: 'relative',
      });
      setActiveLogModal(true);
      setFullWidth({
        gridRowStart,
      });
      dispatch(fetchUserActivityLogs(currentData));
    }
  };
  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof UserInterFace,
      sortAllow: true,
    },
    {
      label: 'User Name',
      accessorKey: 'name' as keyof UserInterFace,
      sortAllow: true,
    },
    // {
    //   label: 'User Id',
    //   accessorKey: 'accountId' as keyof UserInterFace,
    // },
    {
      label: 'Role',
      accessorKey: 'roleName' as keyof UserInterFace,
      sortAllow: true,
    },
    {
      label: 'Contact',
      accessorKey: 'email' as keyof UserInterFace,
      sortAllow: true,
    },
    {
      label: 'Status',
      accessorKey: 'status' as keyof UserInterFace,
      position: 'center',
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: UserInterFace) =>
        currentRow.status ? (
          <div className="tag-success-label">
            <DotCircle /> <span>Active</span>
          </div>
        ) : (
          <div className="tag-danger-label">
            <DotCircle /> <span>Inactive</span>
          </div>
        ),
    },
  ];

  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    userApi
      .exportUsers({ ...filter })
      .then((response) => {
        // Convert data to CSV format
        const headers = ['Date', 'User Name', 'Role', 'Contact', 'Status'];
        const rows = response.data.map((row: UserResponseInterFace) => [
          ConvertUTCtoDate(row.createdAt),
          row.name,
          row?.role ? row?.role?.name : row?.role,
          row.email,
          `${row.status ? 'Active' : 'Inactive'}`,
        ]);
        if (type === 'csv') {
          exportCSV(headers, rows, 'users-list.csv');
        } else {
          exportPDF(headers, rows, 'users-list.pdf');
        }
        setExportLoader(false);
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (users.length === 0) {
      fetchData({
        ...filter,
        status: filter.status ? filter.status === 'active' : undefined,
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
        notification={modalView.notification}
      />
      {openTab === 0 && (
        <>
          <CustomTable
            header={column}
            data={users}
            serialNo
            actionButton
            filter={filter}
            filterFunction={filterFunction}
            filterClearFunction={handleClearFilter}
            sizeChangeButtonRequired
            height={window.innerHeight >= 700 ? 432 : 390}
            page={page}
            limit={limit}
            loading={loading}
            totalPages={totalPages}
            totalResults={totalResults}
            pageLimitChange={pageNateLimitChange}
            actionButtonList={[
              {
                icon: <EditPencil />,
                function: editUser,
                color: 'view-color',
                allow: true,
              },
              {
                icon: <ActivityIcon />,
                function: viewFunction,
                color: 'view-color',
                allow: true,
              },
              {
                icon: <DeleteIcon />,
                function: deleteFunction,
                color: 'delete-color',
                allow: true,
              },
            ]}
            footerLeft={
              <div className="table-bottom-button-list">
                <div className="table-button-item">
                  <ExportButton
                    label="Export Data"
                    buttonList={[
                      {
                        key: 'pdf',
                        allow: true,
                        /**
                         * Export pdf function
                         * @returns
                         */
                        onClickFunction: () => exportDataPdfOrCSV('pdf'),
                      },
                      {
                        key: 'csv',
                        allow: true,
                        /**
                         * Export csv function
                         * @returns
                         */
                        onClickFunction: () => exportDataPdfOrCSV('csv'),
                      },
                    ]}
                    disabled={users.length === 0}
                    loader={exportLoader}
                  />
                </div>
                <div className="table-button-item">
                  <div className="user-count">
                    <div className="icon">
                      <brandIcons.MultiUser />
                    </div>
                    <div className="value">{totalResults} Users</div>
                  </div>
                </div>
              </div>
            }
            rightTopContent={
              <div className="table-top-button-list">
                <div className="table-button-item">
                  <MyButton
                    label=""
                    buttonType="primary-outline"
                    onClickFunc={() => setOpenTab(0)}
                    svgIcon={<TableList />}
                  />
                </div>
                <div className="table-button-item">
                  <MyButton
                    label=""
                    buttonType="back"
                    onClickFunc={() => setOpenTab(1)}
                    svgIcon={<GridList />}
                  />
                </div>
              </div>
            }
          />
          <ActiveLogCard
            closeModal={() => setActiveLogModal(false)}
            activeLogModal={activeLogModal}
            position={position}
          />
        </>
      )}
      {openTab === 1 && (
        <div className="card-details">
          <TopHeaderButtons
            filterFunction={filterFunction}
            filterClearFunction={handleClearFilter}
            sizeChangeFunction={() => {}}
            rightTopContent={
              <div className="table-top-button-list">
                <div className="table-button-item">
                  <MyButton
                    label=""
                    buttonType="back"
                    onClickFunc={() => setOpenTab(0)}
                    svgIcon={<TableList />}
                  />
                </div>
                <div className="table-button-item">
                  <MyButton
                    label=""
                    buttonType="primary-outline"
                    onClickFunc={() => setOpenTab(1)}
                    svgIcon={<GridList />}
                  />
                </div>
              </div>
            }
          />
          <div className="card-list">
            {!loading && users.length === 0 && (
              <div className="table-no-data-wrap">
                <img src={NoDataFound} alt="" className="no-data-found-image" />
                <div className="no-data-found-details">
                  <h3>🔋 Oops! Nothing here yet 🔋</h3>
                  <p>
                    It looks like there&apos;s no data to show right now. Don&apos;t worry—it could
                    just mean things are charging up behind the scenes!
                  </p>
                </div>
              </div>
            )}
            {loading && (
              <div className="table-no-data-wrap">
                <BatteryLoader />
              </div>
            )}
            {activeLogModal && (
              <div className="fixed-grid" style={fullWidth}>
                <ActiveLogCard
                  closeModal={() => setActiveLogModal(false)}
                  activeLogModal={activeLogModal}
                  position={position}
                />
              </div>
            )}
            {!loading && users.map((value: UserInterFace, index: number) => (
              <CardBox key={value.id}>
                <UserCardBody
                  userDetails={value}
                  activeLog={(userId, event) => viewCardActiveFunction(userId, index, event)}
                  activeLogActive
                  menuList={[
                    {
                      content: (
                        <div className="text-primary flex-row">
                          <EditPencil /> Edit
                        </div>
                      ),
                      function: editUser,
                      allow: true,
                    },
                    {
                      content: (
                        <div className="text-danger flex-row">
                          <DeleteIcon /> Delete
                        </div>
                      ),
                      function: deleteFunction,
                      allow: true,
                    },
                  ]}
                />
              </CardBox>
            ))}
          </div>

          <div className="pagination">
            <div className="table-bottom-button-list">
              <div className="table-button-item">
                <ExportButton
                  label="Export Data"
                  buttonList={[
                    {
                      key: 'pdf',
                      allow: true,
                      /**
                       * Export pdf function
                       * @returns
                       */
                      onClickFunction: () => exportDataPdfOrCSV('pdf'),
                    },
                    {
                      key: 'csv',
                      allow: true,
                      /**
                       * Export csv function
                       * @returns
                       */
                      onClickFunction: () => exportDataPdfOrCSV('csv'),
                    },
                  ]}
                  disabled={users.length === 0}
                  loader={exportLoader}
                />
              </div>
              <div className="table-button-item">
                <div className="user-count">
                  <div className="icon">
                    <brandIcons.MultiUser />
                  </div>
                  <div className="value">{totalResults} Users</div>
                </div>
              </div>
            </div>
            <Pagination
              active={page}
              setActive={pageNateLimitChange}
              size={totalPages}
              totalResults={totalResults}
              limit={limit}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersList;
