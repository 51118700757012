/**
 * Function to convert a first letter of the word to uppercase
 * @param value
 * @returns
 */
function CapitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

// Change default export when adding more functions
export default CapitalizeFirstLetter;
