/**
 * Change the date format YYYY-MM-DD function
 * @param value
 * @returns
 */
export const changeDateFormat = (value: string) => {
  if (value === '' || value === undefined) return '';
  const date = new Date(value);
  // const dateMDY = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(date);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};

/**
 * Remove the empty keys in object function
 * @param inputObj
 * @returns
 */
export const removeEmptyKeys = (inputObject: any) => {
  return Object.fromEntries(
    Object.entries(inputObject).filter(
      (value) => value[1] !== '' && value[1] !== null && value[1] !== undefined,
    ),
  );
};

/**
 * Split array
 */
export const splitArrayIntoChunks = (array: number[], chunkSize: number) => {
  const result = [];
  for (let index = 0; index < array.length; index += chunkSize) {
    result.push(array.slice(index, index + chunkSize));
  }
  return result;
};

/**
 * Convert the 12 hours to 24 hours
 * @param time12Hour
 * @returns
 */
export const convertTo24Hour = (time12Hour: string) => {
  const [time, modifier] = time12Hour.split(' ');
  const [hours, minutes] = time.split(':');

  let currentHours = parseInt(hours, 10);
  if (modifier === 'PM' && hours !== '12') {
    currentHours = parseInt(hours, 10) + 12;
  } else if (modifier === 'AM' && hours === '12') {
    currentHours = 0;
  }

  return `${String(currentHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

/**
 * Get the time format 24 to 12 hours
 */
export const convertTo12HourFormat = (time24: string) => {
  const [hours, minutes] = time24.split(':');
  let currentHours = parseInt(hours, 10);

  const period = currentHours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  currentHours = currentHours % 12 || 12; // Converts 0 to 12 for midnight

  return `${String(currentHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
};

/**
 * Get the current period time function
 * @returns
 */
export const getPeriodCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const period = hours >= 12 ? 'PM' : 'AM';

  return `${period}`;
};

/**
 * Time to change the hour and minutes
 * @returns
 */
export const parseTime = (time: string) => {
  const time12Hour = convertTo12HourFormat(time);
  // Split the string by space to separate time and period
  const [timePart, period] = time12Hour.split(" ");
  // Split the time part by colon to separate hours and minutes
  const [hour, minute] = timePart.split(":");
  return {
    hours: parseInt(hour, 10),
    minutes: parseInt(minute, 10),
    noon: period, // Period will be null for 24-hour format
  };
};

/**
 * Array convert to the group 
 * @param array 
 * @param key 
 * @returns 
 */
export const arrayGroupBy = <T extends Record<string, any>>(array: T[], key: keyof T): Record<string, T[]> => {
  return array.reduce((result: Record<string, T[]>, currentItem) => {
    const outputResult = result;
    const group = currentItem[key] as string; // Ensure it's treated as a string key
    if (!outputResult[group]) {
      outputResult[group] = [];
    }
    outputResult[group].push(currentItem);
    return outputResult;
  }, {});
};