import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import * as XLSX from 'xlsx';

import { appCurrentBranding } from '../../../config/branding';
import { QrDetailsProperties } from '../../../interface/qr-generator.interface';
import { useAppDispatch } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import PageTitle from '../../../shared-components/page-title/PageTitle';
import CapitalizeFirstLetter from '../../../shared-functions/StringFunctions';

import QrCodeInputField from './qr-generator/QrCodeInputField';

/**
 * Component to download device qr
 * @returns
 */
function DownloadQr() {
  const dispatch = useAppDispatch();
  const qrReference = useRef<(HTMLDivElement | null)[]>([]); // References for each QR code div
  const [serialNumbersToDownload, setSerialNumbersToDownload] = useState<string[]>([]);
  const [qrDetails, setQrDetails] = useState<QrDetailsProperties>({
    xlFile: null,
    singleDeviceSerialNumber: '',
    deviceSerialNumbers: [],
    size: 170, // Default qr size
    eyeRadius: 0,
    backgroundColor: '#FFFFFF',
    foregroundColor: '#000000',
    qrLogo: null,
    title: {
      text: '',
      position: 'bottom',
    },
    description: {
      text: '',
      position: 'top',
    },
    serialNumberInQr: {
      position: 'bottom',
    },
  });

  /**
   * Handle the change function
   * @param name
   * @param value
   * @returns
   */
  const handleChangeFunction = (name: string, value: string[] | File[] | string | number) => {
    if (name === 'title' || name === 'description') {
      setQrDetails((previous) => ({
        ...previous,
        [name]: {
          ...previous[name],
          text: value.toString(),
        },
      }));
    } else {
      setQrDetails((previous) => ({
        ...previous,
        [name]: value,
      }));
    }
  };

  /**
   * Handle the change function
   * @param name
   * @param value
   * @returns
   */
  const handleRemoveFile = (
    name: string,
    url: string,
    indexToRemove: number,
    type: 'newFile' | 'existingFile',
  ) => {
    if (name) {
      setQrDetails({
        ...qrDetails,
        [name]: null,
      });
    } else {
      // Just to resolve eslint issue - else block will never going to execute
      console.log(url, indexToRemove, type);
    }

    // Set to empty when xlFile is removed
    if (name === 'xlFile') {
      setSerialNumbersToDownload([]);
    }
  };

  /**
   * Function to handle PDF download
   */
  const handleDownloadQRCodePDF = async () => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF();

    try {
      const images = await Promise.all(
        qrReference.current.map(async (qrElement, index) => {
          if (!qrElement) {
            return null; // Skip if null
          }

          try {
            const canvas = await html2canvas(qrElement, {
              useCORS: true, // Ensure cross-origin images load
              logging: false, // Reduce console noise
            });

            const context = canvas.getContext('2d', { willReadFrequently: true });

            if (!context) {
              return null;
            }

            const imgData = canvas.toDataURL('image/png');
            if (!imgData.startsWith('data:image/png')) {
              return null;
            }

            return { imageData: imgData, imageWidth: canvas.width, imageHeight: canvas.height };
          } catch (error) {
            console.error('Error capturing QR code at index', index, error);
            return null;
          }
        }),
      );

      let hasAddedFirstPage = false;

      images.forEach((eachImage) => {
        if (!eachImage) return;

        const { imageData } = eachImage;

        if (hasAddedFirstPage) {
          pdf.addPage();
        } else {
          hasAddedFirstPage = true;
        }

        // Get PDF page dimensions
        // const pageWidth = 794;
        // const pageHeight = 1123;

        // // Calculate center coordinates
        // const x = (pageWidth - imageWidth) / 2;
        // const y = (pageHeight - imageHeight) / 2;

        // Add image at the center of the page
        pdf.addImage(imageData, 'PNG', 25, 25, 0, 0);
      });

      if (hasAddedFirstPage) {
        pdf.save('QR_Codes.pdf');
      } else {
        console.warn('No valid QR codes found to generate PDF.');
        dispatch(
          setNotificationMessage({
            message: 'No valid QR codes found to generate PDF.',
            status: true,
            type: 'error',
            code: 400,
          }),
        );
      }
    } catch (error) {
      console.error('Error generating QR code PDF:', error);
    }
  };

  useEffect(() => {
    if (qrDetails.xlFile?.length && qrDetails.xlFile?.length > 0) {
      qrDetails.xlFile.forEach((eachFile) => {
        const reader = new FileReader();

        /**
         * Function to excel format data
         * @param e
         */
        reader.onload = (event) => {
          if (event.target?.result) {
            const data = new Uint8Array(event.target.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });

            // Get the first sheet name
            const sheetName = workbook.SheetNames[0];

            // Get the sheet data
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            const deviceSnList: string[] = [];
            sheetData.forEach((eachRow: any) => {
              if (eachRow.DeviceSn) {
                deviceSnList.push(eachRow.DeviceSn.toString());
              }
            });

            setQrDetails((previousValue) => ({
              ...previousValue,
              deviceSerialNumbers: deviceSnList,
            }));

            setSerialNumbersToDownload(deviceSnList);
          }
        };

        reader.readAsArrayBuffer(eachFile); // Read as ArrayBuffer
      });
    }
  }, [qrDetails.xlFile]);

  useEffect(() => {
    if (qrDetails.singleDeviceSerialNumber) {
      setSerialNumbersToDownload([qrDetails.singleDeviceSerialNumber]);
    } else if (qrDetails.singleDeviceSerialNumber !== '') {
      setSerialNumbersToDownload([]);
    }
  }, [qrDetails.singleDeviceSerialNumber]);

  return (
    <div className="download-qr-with-functionality-wrap">
      <div className="download-qr-wrap">
        <PageTitle title="QR Code Generator " />
        <div className="download-qr-container">
          <div className="download-qr-input-wrap">
            <QrCodeInputField
              qrDetails={qrDetails}
              handleChangeFunction={handleChangeFunction}
              handleRemoveFile={handleRemoveFile}
            />
            <MyButton
              label="Download QR Code"
              buttonType="submit"
              onClickFunc={handleDownloadQRCodePDF}
            />
          </div>
          <div className="download-qr-preview-wrap">
            <div className="download-qr-single-container">
              <div className="qr-top-details">
                <p>
                  {qrDetails?.title?.text
                    ? qrDetails.title.text
                    : CapitalizeFirstLetter(appCurrentBranding)}
                </p>
                <p>
                  {qrDetails?.description?.text ? qrDetails.description.text : 'Scan to Charge'}
                </p>
              </div>
              <div className="qr-container">
                <QRCode
                  value={`${process.env.REACT_APP_QR_CODE_URL}?deviceId=${serialNumbersToDownload?.[0]}`}
                  size={qrDetails.size <= 999 ? qrDetails.size : 170}
                  logoImage={
                    qrDetails.qrLogo ? URL.createObjectURL(qrDetails.qrLogo[0]) : undefined
                  }
                  removeQrCodeBehindLogo
                />
              </div>
              <div className="qr-bottom-details">
                <p>
                  {serialNumbersToDownload?.length
                    ? serialNumbersToDownload[0]
                    : 'Device Serial Number'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hide this container by give max-height and overflow hidden for parent container */}
      <div
        className="download-qr-functionality-wrap"
        style={{ opacity: '0 !important', pointerEvents: 'none' }}>
        {/* Don't edit this inline CSS */}
        {serialNumbersToDownload.map((serialNumber, index) => (
          <div
            className="download-qr-single-container"
            ref={(element) => {
              qrReference.current[index] = element;
            }}>
            <div className="qr-top-details">
              <p>
                {qrDetails?.title?.text
                  ? qrDetails.title.text
                  : CapitalizeFirstLetter(appCurrentBranding)}
              </p>
              <p>{qrDetails?.description?.text ? qrDetails.description.text : 'Scan to Charge'}</p>
            </div>
            <div className="qr-container">
              <QRCode
                value={`${process.env.REACT_APP_QR_CODE_URL}?deviceId=${serialNumber}`}
                size={qrDetails.size <= 999 ? qrDetails.size : 170}
                logoImage={qrDetails.qrLogo ? URL.createObjectURL(qrDetails.qrLogo[0]) : undefined}
                removeQrCodeBehindLogo
              />
            </div>
            <div className="qr-bottom-details">
              <p>{serialNumber || 'Device Serial Number'}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DownloadQr;
