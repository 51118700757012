import deleteRequest from './base/delete';
import getRequest from './base/get';
import patchRequest from './base/patch';
import postRequest from './base/post';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchAccounts: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query, limit: query?.limit || 5 };
    console.log(parameters);
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/`, { ...parameters });
  },
  /**
   * Get the all data for export the Accounts list without pagination
   * @param query 
   * @returns 
   */
  exportData: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/export-accounts`, { ...parameters });
  },
  /**
   * Fetch single Accounts list with pagination
   */
  fetchOneAccounts: (query: string): Promise<any> => {
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/${query}`, {});
  },
  /**
   * create the Utility provider account
   */
  createUtilityAccounts: (data: any): Promise<any> => {
    const parameters = { ...data };
    return postRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/`, parameters);
  },
  /**
   * Edit the Utility provider account
   * @param id 
   * @param data 
   * @returns 
   */
  editUtilityAccounts: (id: string, data: any): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/${id}`, { ...parameters });
  },
  /**
   * Approved the account function
   * @param id 
   * @param data 
   * @returns 
   */
  approvedAccounts: (id: string, data: any): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/verify-account/${id}`, { ...parameters });
  },
  /**
   * Delete the account api call function
   * @param data
   * @returns
   */
  deleteAccount: (
    id: String,
  ): Promise<any> => {
    return deleteRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/utility-provider/${id}`);
  },
};
